import styled, { css } from 'styled-components';
import { colors, updatedMedia } from 'client/styles';

import {
  ArrowWrapperLeft,
  ArrowWrapperRight,
  CarouselPhotosBase,
  Container,
  Slides,
} from 'src/comps/CarouselPhotos/styles';
import { DiscountTagBase } from 'client/components/DiscountTag/styles';
import { DiscountTagLoaderBase } from 'client/components/DiscountTagLoader/styles';
import { ImageBase } from 'src/comps/Image/styles';
import { PhotoBase } from 'client/components/Photo/styles';
import { StarsBase } from 'client/components/Stars/styles';
import { Paragraph } from 'src/styles/typography/typography';

export const PropertyHeader = styled.div`
  align-items: start;
  display: grid;
  grid-template-areas:
    'nameScore nameScore'
    'location location'
    'scoreReviews scoreReviews';
  column-gap: 10px;
  row-gap: 0.25em;
  grid-template-columns: 1fr auto;
  margin-bottom: 6px;
`;

export const Info = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;

  ${updatedMedia.desktop} {
    padding: 16px;
  }
  ${updatedMedia.tablet} {
    ${props => props.horizontal && css`
      width: 63%;
      min-height: 240px;
    `}
  }
`;

export const FacilitiesPlaceholder = styled.div`
  height: 34px;
  width: 120px;
  line-height: 1;
`;

export const PricePlaceholder = styled(FacilitiesPlaceholder)`
  float: right;
`;

export const SoldOut = styled.span`
  background-color: ${props => props.theme.colors.failure};
  border-radius: 8px;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 700;
  left: 50%;
  top: 50%;
  padding: 8px 24px;
  position: absolute;
  text-align: center;
  z-index: 10;
  transform: translate(-50%, -50%) rotate(-10deg);
  text-transform: capitalize;
`;

export const ButtonRemove = styled.button`
  background-color: ${colors.white};
  border-radius: 3px;
  border: none;
  cursor: pointer;
  height: 33px;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 33px;
  z-index: 10;

  :focus {
    outline: none;
  }

  :hover {
    box-shadow: inset 0 0 0 2px ${colors.magenta};
  }

  svg {
    fill: ${colors.grey1};
  }
`;

export const Location = styled.div`
  grid-area: location;
  display: flex;
  align-items: flex-start;
  line-height: 20px;
`;

export const LocationLine = styled.div`
  display: flex;
  width: 100%;
`;

export const Address = styled.p`
  color: ${colors.grey2};
  flex: 0 1 auto;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
  white-space: nowrap;

  :first-letter {
    text-transform: uppercase;
  }
`;

export const Separator = styled.span`
  flex: 0 0 auto;
  line-height: 1;
  margin: 0 0.3em;
  vertical-align: middle;
  font-size: 14px;
`;

export const Message = styled.p`
  color: ${props => (props.message ? colors.green : colors.grey3)};
  font-size: 13px;
  line-height: 1.3;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const Facilities = styled.div`
  flex: 1 1 auto;
  margin-right: 6px;
  justify-content: start;
`;

export const NameScore = styled.div`
  grid-area: nameScore;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NameRating = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;

  ${StarsBase} {
    margin-top: 6px;
  }
`;

export const Name = styled.h3`
  color: ${colors.grey1};
  font-size: 21px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: ${1.3 * 21}px;
`;

export const BottomPlaceholders = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SaveMoney = styled.p`
  color: ${colors.magenta};
  display: block;
  font-size: 13px;
  line-height: 1.2;
  text-align: right;
`;

export const Board = styled.div`
  color: ${colors.green};
`;

export const Placeholder = styled.li`
  background-color: ${colors.white};
  border-radius: 5px;
  border: 2px solid ${colors.grey4};
  width: 100%;
  overflow: hidden;
  list-style: none;
`;

export const FacilityContainer = styled.li`
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const PropertyBase = styled.div`
  font-family: ${({ theme }) => theme.fonts.families.primary};
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: ${colors.white};
  border-radius: 6px;
  line-height: 1.4em;

  ${updatedMedia.tablet} {
    ${ArrowWrapperLeft}, ${ArrowWrapperRight} {
      display: none;
    }

    @media (hover) {
      &:hover {
        ${ArrowWrapperLeft}, ${ArrowWrapperRight} {
          display: flex;
        }
      }
    }
  }

  ${props =>
    props.wide &&
    css`
      ${updatedMedia.tablet} {
        flex-direction: row;

        ${PhotoBase} {
          border-radius: 0;
          min-height: none;
        }

        ${NameRating} {
          flex: 1 0 auto;
          display: flex;
          align-items: flex-start;
          margin-bottom: 4px;

          h3 {
            margin-right: 8px;
            margin-bottom: 0px;
          }
        }

        ${Facilities} {
          margin-bottom: 6px;
        }

        ${NameScore} {
          margin-bottom: 0px;
        }

        ${CarouselPhotosBase} {
          flex: 0 0 auto;
          height: 100%;
        }

        ${PriceWrapper} {
          flex-direction: column;
        }

        ${Container}, ${Slides}, ${ImageBase},${ImageBase} > img {
          height: 100%;
        }

      }

      ${props.isHover && `border-color: ${props.theme.colors.grey2};}`}
      
      &:hover {
        border-color: ${props.theme.colors.grey2};
      }
    `}
  ${({ showNightNetPrice }) => showNightNetPrice && css`
    ${PriceWrapper} {
      flex-direction: column;
      align-items: flex-start !important;
    }
  `}

  ${DiscountTagBase}, ${DiscountTagLoaderBase} {
    position: absolute;
    top: 12px;
    left: 20px;
    z-index: 10;
    max-width: calc(100% - 12px);
  }
`;

export const MediaContainer = styled.div`
  position: relative;
  ${props => props.horizontal && css`
    ${updatedMedia.tablet} {
      width: 37%;
    }
  `}
`;

export const PropertyWrapper = styled.a`
  display: block;
  border-radius: 8px;
  cursor: pointer;
  padding: 2px;
  background-color: ${props => props.theme.colors.grey5};
  text-decoration: none;

  ${props => props.highlightedAid && props.highlighted &&
    css`
      background-color: ${props.theme.colors.success};     
    `
  }
  ${props => props.highlighted && !props.highlightedAid && css`
    background-color: ${props.theme.colors.brand1};
  `}

  ${props => props.isHover && !props.highlighted && css`
    background-color: ${props.theme.colors.grey2};
  `}

  @media (hover) {
    ${props =>
      !(props.highlightedAid && props.highlighted) &&
      css`
        &:hover {
          background-color: ${props.theme.colors.grey2};
        }
      `}
  }
`;

export const HighlightedText = styled(Paragraph)`
  margin: 6px 10px 8px;
  display: flex;

  span {
    text-transform: capitalize;
  }
`;
