import styled from 'styled-components';
import { colors } from 'client/styles';

export const DiscountTagLoaderBase = styled.div`
  width: 55px;
  height: 32px;
`;

export const inlineStyles = {
  primary: colors.grey4,
  secondary: colors.white,
};
