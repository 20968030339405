import { defineMessages } from 'react-intl';

const messages = defineMessages({
  book: {
    id: 'global.book',
  },
  distance: {
    id: 'accommodation.center-distance',
  },
  inCenter: {
    id: 'accommodation.in-center',
  },
  fullyBooked: {
    id: 'global.fully-booked',
  },
  saveMoney: {
    id: 'global.save-money',
  },
  stayWalletEarn: {
    id: 'staywallet.earn-list',
  },
  stayWalletEarnTooltip: {
    id: 'staywallet.earn-list-tooltip',
  },
  nights: {
    id: 'global.nights',
  },
  soldOut: {
    id: 'global.sold-out',
  },
  tooLate: {
    id: 'global.too-late',
  },
  seeOtherDates: {
    id: 'global.see-other-dates',
  },
  notAvailable: {
    id: 'global.not-available-for-these-dates',
  }
});

export default messages;
