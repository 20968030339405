import { useState, useEffect } from 'react';
import noop from 'lodash/noop';

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
};

export function useLazyLoading(ref) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const node = ref?.current;

    if (!node || !('IntersectionObserver' in window)) {
      return noop();
    }

    const observer = new IntersectionObserver(([entry], instance) => {
      if (entry.isIntersecting) {
        setLoaded(true);
        instance.unobserve(node);
      }
    }, options);

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loaded;
}
