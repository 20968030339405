import styled from 'styled-components';
import { colors } from 'client/styles';
import { SIZE_SMALL, SIZE_MEDIUM, SIZE_BIG } from './constants';

export const ScoreBase = styled.span`
  display: flex;
  font-weight: 700;
  line-height: 1.2;
  align-items: center;
  grid-area: score;
`;

export const ScoreRating = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.variant === 'square' ? props.color : 'transparent')};
  border-radius: 5px;
  color: ${props => (props.variant === 'square' ? colors.white : props.color)};

  ${props => props.size === SIZE_SMALL && 'font-size: 12px; width: 26px; height: 26px;'}
  ${props => props.size === SIZE_MEDIUM && 'font-size: 16px; width: 35px; height: 35px;'}
  ${props => props.size === SIZE_BIG && 'font-size: 21px; width: 50px; height: 50px;'}
`;

export const Category = styled.span`
  color: ${props => props.color};
  display: flex;
  font-weight: 700;
  text-transform: capitalize;
  margin-left: 10px;

  ${props => props.size === SIZE_SMALL && 'font-size: 16px;'}
  ${props => props.size === SIZE_MEDIUM && 'font-size: 16px;'}
  ${props => props.size === SIZE_BIG && 'font-size: 21px;'}
`;
