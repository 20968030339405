import styled, { css, Theme } from 'styled-components';
import { IPromo } from 'src/types/promo';

function getPromo(color: string, background: string) {
  return css`
    background-color: ${background};
    color: ${color};
    width: auto;
  `;
}

interface Props {
  promo?: IPromo;
  prime: boolean;
  theme: Theme;
}

function getTemplate({ promo, prime, theme }: Props) {
  if (prime === true) {
    return getPromo(theme.colors.grey8, theme.colors.prime1);
  }

  if (promo) {
    return getPromo(promo.global.tags.color, promo.global.tags.background);
  }

  const bgColor = theme.colors.brand1;

  return css`
    background-color: ${bgColor};
    color: ${theme.colors.grey8};
    text-transform: lowercase;
  `;
}

export const DiscountTagBase = styled.div<Props>`
  ${props => getTemplate(props)};

  display: flex;
  align-items: center;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;

  text-align: left;
  min-width: 65px;
  padding: 4px 6px 4px 20px;
  border-radius: 0 8px 8px 0;
  margin: 0 auto 6px -20px;
`;

export const PrimeDiscountText = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;

  svg {
    fill: ${({ theme }) => theme.colors.grey8};
    margin-right: 6px;
  }
`;
