import styled, { css } from 'styled-components';
import { colors } from 'client/styles';

export const MobileOnlyContainer = styled.div`
  display: flex;
  color: ${colors.white};
  background-color: ${colors.green4};
  border-radius: 0 8px 8px 0;
  padding: 4px 8px 4px 20px;
  width: max-content;
  margin-left: -20px;
`;

export const MobileOnlyBase = styled.div`
  line-height: 22px;
  margin-bottom: ${props => (props.as === 'li' ? '4px' : '8px')};
  margin-top: 0;
  width: 100%;

  svg {
    flex: 0 0 auto;
    fill: ${colors.white};
    width: 20px;
    height: 20px;
  }

  p {
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
    font-weight: 700;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  ${props => props.variant === 'text' && css`
    margin-bottom: 6px;
    svg {
      fill: ${props.theme.colors.success}
    }
    p {
      color: ${props.theme.colors.success};
      font-size: 16px;
    }

    ${MobileOnlyContainer} {
      background-color: ${props.theme.colors.grey8};
    }
  `}
`;
