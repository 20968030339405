import styled from 'styled-components';
import { colors } from 'client/styles';

export const ArrowWrapper = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 16px);
`;

export const ArrowBase = styled.span`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 50%;
  color: ${colors.grey1};
  cursor: pointer;
  display: none;
  height: 32px;
  justify-content: center;
  width: 32px;
  z-index: 10;

  &:hover {
    background-color: ${colors.grey5};
  }
`;

export const ArrowWrapperLeft = styled(ArrowWrapper)`
  left: 10px;
`;

export const ArrowWrapperRight = styled(ArrowWrapper)`
  right: 10px;
`;

export const CarouselPhotosBase = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  &:hover ${ArrowBase} {
    display: flex;
  }
`;

export const Container = styled.div`
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Slides = styled.div`
  display: flex;
  transition: transform 0.25s ease-in-out;
  ${props => props.slide && `transform: translate(calc(${props.current} * -100%))`};
`;

export const Slide = styled.div`
  flex: 0 0 auto;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 100%;
`;
