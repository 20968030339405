import { colors } from 'client/styles';

export function getScoreRange(score) {
  const values = { higher: 8.5, high: 8, medium: 6.5, low: 5 };

  if (score >= values.higher) {
    return { level: 1, color: colors.green4 };
  }

  if (score >= values.high && score < values.higher) {
    return { level: 2, color: colors.green4 };
  }

  if (score >= values.medium && score < values.high) {
    return { level: 3, color: colors.green4 };
  }

  if (score >= values.low && score < values.medium) {
    return { level: 4, color: colors.orange };
  }

  return { level: 5, color: colors.red };
}
