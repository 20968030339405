import { defineMessages } from 'react-intl';

const messages = defineMessages({
  score1: {
    id: 'score.range-1',
  },
  score2: {
    id: 'score.range-2',
  },
  score3: {
    id: 'score.range-3',
  },
  score4: {
    id: 'score.range-4',
  },
  score5: {
    id: 'score.range-5',
  },
});

export default messages;
