import { useMarket } from 'src/hooks/useMarket';
import { useBusiness } from 'src/hooks/useBusiness';
import { DEFAULT_AFFILIATE_ID } from 'src/pages/_middleware/defaultAffiliate';

import { Room } from 'src/types/search';
import { PriceType, RoomTypes } from 'src/types/prices';
import {
  AFFILIATE_FINDHOTEL,
  AFFILIATE_HOTELSCAN,
  AFFILIATE_IDEALO,
  AFFILIATE_TRIPADVISOR,
  AFFILIATE_TRIVAGO,
  AFFILIATE_VIO,
} from 'src/constants/affiliates';

const countriesToShowNightNetPrices = ['us', 'ca'];
const countriesToShowAllInPrices = ['co', 'mx', 'br'];
const countriesToShowAllInDailyPrices = ['au'];

const getRoomType = (rooms: Room[]) => {
  // onnly one room
  if (rooms.length === 1) {
    return RoomTypes.Single;
  }
  // all rooms have the same number of adults and children with the same age
  if (
    rooms.every(
      room =>
        room.adults === rooms[0].adults &&
        room.children.length === rooms[0].children.length &&
        room.children.every((child, index) => child === rooms[0].children[index])
    )
  ) {
    return RoomTypes.MultiRoom;
  }
  // some rooms have different number of adults or children
  return RoomTypes.MultiBooking;
};

export const getPriceAndRoomType = ({
  affiliate,
  market,
  rateModel,
  roomType,
}: {
  roomType: RoomTypes;
  market: string;
  affiliate: string;
  rateModel?: PriceType | null;
}) => {
  if (rateModel) {
    return {
      priceType: rateModel,
      roomType,
    };
  }

  if (affiliate === DEFAULT_AFFILIATE_ID) {
    const countryAffiliatePriceFormat: Record<string, PriceType> = {
      us: PriceType.AllIn,
    };

    if (countryAffiliatePriceFormat?.[market]) {
      return {
        priceType: countryAffiliatePriceFormat[market],
        roomType,
      };
    }
  }

  if (affiliate === AFFILIATE_TRIVAGO) {
    const countryAffiliatePriceFormat: Record<string, PriceType> = {
      us: PriceType.TrivagoNet,
      ca: PriceType.TrivagoNet,
      br: PriceType.TrivagoNet,
      mx: PriceType.TrivagoNet,
      za: PriceType.Gross,
    };

    return {
      priceType: countryAffiliatePriceFormat[market] ?? PriceType.AllIn,
      roomType,
    };
  }

  if (affiliate === AFFILIATE_TRIPADVISOR) {
    const countryAffiliatePriceFormat: Record<string, PriceType> = {
      us: PriceType.TrivagoNet,
      ca: PriceType.TrivagoNet,
      fr: PriceType.AllIn,
      au: PriceType.AllIn,
      br: PriceType.TrivagoNet,
      mx: PriceType.TrivagoNet,
    };

    return {
      priceType: countryAffiliatePriceFormat[market] ?? PriceType.AllInDaily,
      roomType,
    };
  }

  if ([AFFILIATE_FINDHOTEL, AFFILIATE_VIO].includes(affiliate)) {
    return {
      priceType: PriceType.AllInDaily,
      roomType,
    };
  }

  if ([AFFILIATE_HOTELSCAN, AFFILIATE_IDEALO].includes(affiliate)) {
    if (['mx', 'br'].includes(market)) {
      return {
        priceType: PriceType.Gross,
        roomType,
      };
    }
  }

  const showNightNetPrice = countriesToShowNightNetPrices.includes(market);
  if (showNightNetPrice) {
    return {
      priceType: PriceType.Net,
      roomType,
    };
  }

  const showAllInPrice = countriesToShowAllInPrices.includes(market);
  if (showAllInPrice) {
    return {
      priceType: PriceType.AllIn,
      roomType,
    };
  }

  const showAllInDailyPrice = countriesToShowAllInDailyPrices.includes(market);
  if (showAllInDailyPrice) {
    return {
      priceType: PriceType.AllInDaily,
      roomType,
    };
  }

  return {
    priceType: PriceType.Gross,
    roomType,
  };
};

export function usePrices({ rooms }: { rooms: Room[] }) {
  const { market } = useMarket();
  const { affiliate, rateModel } = useBusiness();

  const checkedRatedModel =
    rateModel && Object.values(PriceType).includes(rateModel) ? rateModel : null;

  const roomType = getRoomType(rooms);

  return getPriceAndRoomType({
    market,
    affiliate,
    rateModel: checkedRatedModel,
    roomType,
  });
}
