import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDevice } from 'src/hooks/useDevice';

import MobilePhone from 'client/icons/MobilePhone';

import { MobileOnlyBase, MobileOnlyContainer } from './styles';

import messages from './messages';

const MobileOnlyPrice = ({ isMobileRate, as = 'div', variant = 'label' }) => {
  const { device } = useDevice();
  if (!isMobileRate || device === 'desktop') {
    return null;
  }
  
  return (
    <MobileOnlyBase data-testid="is-mobile-price" as={as} variant={variant}>
      <MobileOnlyContainer>
        <MobilePhone />
        <FormattedMessage {...messages.mobileOnlyTag} tagName="p" />
      </MobileOnlyContainer>
    </MobileOnlyBase>
  );
};

MobileOnlyPrice.propTypes = {
  as: PropTypes.string,
  isMobileRate: PropTypes.bool,
  variant: PropTypes.oneOf(['label', 'text']),
};

export default MobileOnlyPrice;
