import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { LocationLine, Address } from './styles';
import messages from './messages';
import { ONE_KILOMETER_IN_MILES } from 'src/constants/convertUnits';
import { COUNTRIES, MEASUREMENT_UNITS } from 'src/constants/countries';
import { useMarket } from 'src/hooks/useMarket';

const PropertyLocation = ({ distance, isInCenter, location, showLocation = false }) => {
  const intl = useIntl();

  const { market } = useMarket()

  if (!location) {
    return null;
  }

  function getFullAddress() {
    const marketMeasurementSystem = COUNTRIES[market]?.measurementSystem || MEASUREMENT_UNITS.METRIC;
    const distanceInMarketUnit = marketMeasurementSystem === MEASUREMENT_UNITS.IMPERIAL ? distance * ONE_KILOMETER_IN_MILES : distance;
    const distanceTracked = Math.round(distanceInMarketUnit * 10) / 10;
    const hasDistanceInfo = !!(distance || isInCenter);
    const distanceFromCenter = { distance: distanceTracked };

    const centerText = isInCenter
      ? intl.formatMessage(messages.inCenter)
      : intl.formatMessage(messages.distance, { distance: distanceFromCenter?.distance });

    const composedAddress = showLocation ? `${location} · ${centerText}` : centerText;

    return hasDistanceInfo ? composedAddress : location;
  }

  return (
    <LocationLine>
      <Address data-testid="address" title={getFullAddress()}>
        {getFullAddress()}
      </Address>
    </LocationLine>
  );
};

PropertyLocation.propTypes = {
  distance: PropTypes.number,
  isInCenter: PropTypes.bool,
  location: PropTypes.string,
  showLocation: PropTypes.bool,
};

export default PropertyLocation;
