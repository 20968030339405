import { ImageApiObject } from 'src/types/image';
import { imageHandler } from 'src/helpers/imageHandler';

export function getImage(currentSlide: number, thumbnails: Array<string>) {
  return thumbnails[currentSlide]?.replace(/\/thumb\/|\/medium\//g, '/big/');
}

export function getImageForSize(image: string, size: string) {
  return image.replace(/\/medium\//g, `/${size}/`);
}

interface DevicePhotosInfo {
  [key: string]: {
    indexOfPhotoToLazy: number;
    photoSize: string;
    avgPhotoHeight: number;
  };
}

export const DEVICE_PHOTOS_INFO: DevicePhotosInfo = {
  mobile: {
    indexOfPhotoToLazy: 3,
    photoSize: 'medium',
    avgPhotoHeight: 235,
  },
  tablet: {
    indexOfPhotoToLazy: 4,
    photoSize: 'big',
    avgPhotoHeight: 450,
  },
  desktop: {
    indexOfPhotoToLazy: 6,
    photoSize: 'big',
    avgPhotoHeight: 320,
  },
};

export enum ImageSrcType {
  Responsive,
  Default,
}

export interface ISizes {
  desktop: {
    size: string;
    srcWidth: number;
  };
  tablet: {
    size: string;
    srcWidth: number;
  };
  mobile: {
    size: string;
    srcWidth: number;
  };
}

export const getResponsiveImgProps = (img: ImageApiObject, sizes: ISizes) => ({
  src: imageHandler.renderSrc({ imgObj: img, width: sizes.mobile.srcWidth }),
  srcSet: imageHandler.renderSrcSet(
    img,
    sizes?.desktop?.srcWidth,
    sizes?.tablet?.srcWidth,
    sizes.mobile.srcWidth
  ),
  sizes: imageHandler.renderSizes({
    desktop: sizes?.desktop?.size,
    tablet: sizes?.tablet?.size,
    mobile: sizes.mobile.size,
  }),
});
