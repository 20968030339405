import styled from 'styled-components';
import { colors } from 'client/styles';

export const PhotoBase = styled.div`
  background-color: ${colors.grey4};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
  height: ${props => (props.maxHeight ? `${props.maxHeight}px;` : '100%')};

  img {
    ${props => props.deSaturated && 'filter: grayscale(1);'}
  }
`;

export const PhotoPlaceholder = styled.div`
  align-items: center;
  background-color: ${colors.grey4};
  display: flex;
  height: 100%;
  justify-content: center;

  svg {
    fill: ${colors.grey2};
  }
`;
