import { Room } from 'src/types/search';

export enum PriceType {
  AllIn = 'all_in', // Precio por noche (total + fees) + total con impuestos y fees
  AllInDaily = 'all_in_daily', // Precio por noche (total + fees) + total con impuestos y fees
  Net = 'net', // Precio por noche (neto) + total con impuestos
  Gross = 'gross', // total + total con impuestos
  TrivagoNet = 'trivago_net', // Precio por noche (neto) + total sin impuestos
}

export interface IPriceBase {
  priceType: PriceType;
  price: number;
  currency: string;
  priceOld: number;
  prime: boolean;
  rooms: Room[];
}

export enum RoomTypes {
  Single = 'single',
  MultiRoom = 'multiroom',
  MultiBooking = 'multibooking',
}
