/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedNumber, useIntl } from 'react-intl';

import { getBaseParams } from 'src/comps/Page/selectors';
import { MIN_PRICE_DIGITS, MAX_PRICE_DIGITS } from './constants';

const Price = ({ amount, currency: currencyProp }) => {
  const intl = useIntl();
  const { market, currency: currencyConfig } = useSelector(getBaseParams);
  const currency = currencyProp || currencyConfig;

  if (['au', 'ca'].includes(market)) {
    const price = intl.formatNumber(amount, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: MIN_PRICE_DIGITS,
      maximumFractionDigits: MAX_PRICE_DIGITS,
    });

    return <>{price.replace(/(A|C)?\$/, market === 'ca' ? 'CA$' : 'AU$')}</>;
  }

  if (market === 'ro') {
    const price = intl.formatNumber(amount, {
      style: 'currency',
      currency,
      currencyDisplay: 'name',
      minimumFractionDigits: MIN_PRICE_DIGITS,
      maximumFractionDigits: MAX_PRICE_DIGITS,
    });

    return <>{price.replace(/(\s)?(de)?\slei\sromânești/gm, '\u00a0lei')}</>;
  }

  if (market === 'no') {
    const price = intl.formatNumber(amount, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: MIN_PRICE_DIGITS,
      maximumFractionDigits: MAX_PRICE_DIGITS,
    });

    return <>{`${price.replace('kr', currency)}`}</>;
  }

  if (market === 'mx') {
    const price = intl.formatNumber(amount, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: MIN_PRICE_DIGITS,
      maximumFractionDigits: MAX_PRICE_DIGITS,
    });

    return <>{price}</>;
  }

  const formattedPrice = (
    <FormattedNumber
      value={amount}
      style="currency"
      currency={currency}
      currencyDisplay="symbol"
      minimumFractionDigits={MIN_PRICE_DIGITS}
      maximumFractionDigits={MAX_PRICE_DIGITS}
    />
  );

  return formattedPrice;
};

Price.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string,
};

export default Price;
