import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';

import { DEFAULT_OFFSET } from 'src/comps/CarouselPhotos/constants';

import CarouselDots from 'src/comps/CarouselDots';
import IconArrowLeft from 'client/icons/ChevronLeft';
import IconArrowRight from 'client/icons/ChevronRight';

import {
  ArrowWrapperLeft,
  ArrowWrapperRight,
  ArrowBase,
  CarouselPhotosBase,
  Container,
  Slide,
  Slides,
} from './styles';
import { breakpoint } from 'client/styles';
import { useDevice } from 'src/hooks/useDevice';

const CarouselPhotos = ({ children, photos = [], limit = DEFAULT_OFFSET, handleSwipeEvent }) => {
  const { device } = useDevice();
  const isWide = useMedia(breakpoint.tablet, device === 'desktop') && device !== 'tablet';
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideX, setSlideX] = useState(0);

  const currentOffset = useMemo(
    () => ((((currentSlide + 1) / limit) >> 0) + 1) * limit, // eslint-disable-line no-bitwise
    [currentSlide, limit]
  );

  if (!photos || photos.length === 0 || !children) {
    return null;
  }

  const initSlide = currentSlide === 0;
  const lastSlide = currentSlide === photos.length - 1;

  function unifyEvents(event) {
    return event.changedTouches ? event.changedTouches[0] : event;
  }

  function startSlide(event) {
    setSlideX(unifyEvents(event).clientX);
  }

  function prevSlide(event) {
    if (event.cancelable) {
      event.preventDefault();
      event.stopPropagation();
    }

    return !initSlide && setCurrentSlide(currentSlide - 1);
  }

  function nextSlide(event) {
    if (event.cancelable) {
      event.preventDefault();
      event.stopPropagation();
    }

    return !lastSlide && setCurrentSlide(currentSlide + 1);
  }

  function changeSlide(event) {
    const slideDiff = unifyEvents(event).clientX - slideX;
    const direction = Math.sign(slideDiff);

    if (direction < 0) {
      nextSlide(event);
      if (typeof handleSwipeEvent === 'function' && !lastSlide) {
        handleSwipeEvent(currentSlide + 1);
      }
    }

    if (direction > 0) {
      prevSlide(event);
      if (typeof handleSwipeEvent === 'function' && !initSlide) {
        handleSwipeEvent(currentSlide - 1);
      }
    }

    setSlideX(0);
  }

  const pointerEvents = !isWide && {
    onMouseDown: startSlide,
    onMouseUp: changeSlide,
    onTouchEnd: changeSlide,
    onTouchStart: startSlide,
  };

  return (
    <CarouselPhotosBase>
      <Container>
        <Slides current={currentSlide} slide={isWide}>
          {photos.slice(0, currentOffset).map(item => {
            const index = photos.findIndex(({ id }) => id === item?.id);
            const { src } = item;
            const key = `${src}${index}`;

            return (
              <Slide data-testid="slide-photo" key={key} {...pointerEvents}>
                {children({ item, firstItem: index === 0, photo: item })}
              </Slide>
            );
          })}
        </Slides>
      </Container>

      <CarouselDots dots={photos} current={currentSlide} />

      <ArrowWrapperLeft onClick={prevSlide}>
        {isWide && !initSlide && (
          <ArrowBase>
            <IconArrowLeft size={16} />
          </ArrowBase>
        )}
      </ArrowWrapperLeft>

      <ArrowWrapperRight onClick={nextSlide}>
        {isWide && !lastSlide && (
          <ArrowBase>
            <IconArrowRight size={16} />
          </ArrowBase>
        )}
      </ArrowWrapperRight>
    </CarouselPhotosBase>
  );
};

CarouselPhotos.propTypes = {
  children: PropTypes.func,
  handleSwipeEvent: PropTypes.func,
  photos: PropTypes.instanceOf(Array),
  limit: PropTypes.number,
};

export default CarouselPhotos;
