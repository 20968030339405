import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getScoreRange } from './utils';
import {
  MAX_SCORE,
  MIN_SCORE,
  VARIANT_NUMBER,
  VARIANT_SQUARE,
  SIZE_BIG,
  SIZE_MEDIUM,
  SIZE_SMALL,
} from './constants';
import { ScoreRating, ScoreBase, Category } from './styles';
import messages from './messages';

const Score = ({
  value = 0,
  variant = VARIANT_SQUARE,
  withCategory = false,
  size = SIZE_MEDIUM,
}) => {
  if (!value || value < MIN_SCORE || value >= MAX_SCORE) {
    return null;
  }

  const score = parseFloat(value).toFixed(1);
  const category = getScoreRange(score);

  return (
    <ScoreBase>
      <ScoreRating data-testid="score" color={category.color} variant={variant} size={size}>
        {score}
      </ScoreRating>
      {withCategory && (
        <Category color={category.color} size={size} data-testid="category">
          <FormattedMessage {...messages[`score${category.level}`]} />
        </Category>
      )}
    </ScoreBase>
  );
};

Score.propTypes = {
  value: PropTypes.number,
  variant: PropTypes.oneOf([VARIANT_NUMBER, VARIANT_SQUARE]),
  size: PropTypes.oneOf([SIZE_BIG, SIZE_MEDIUM, SIZE_SMALL]),
  withCategory: PropTypes.bool,
};

export default Score;
