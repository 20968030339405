import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import IconPicture from 'client/icons/Picture';
import Image from 'src/comps/Image';

import { useLazyLoading } from 'src/hooks/useLazyLoading';
import { PhotoBase, PhotoPlaceholder } from './styles';

const Photo = ({ deSaturated, lazyLoad, photo, name, width = 250, height = 180, isResponsive, aspectRatio = null, ...rest }) => {
  const ref = useRef(null);
  const inViewport = useLazyLoading(ref);

  return (
    <PhotoBase ref={ref} deSaturated={deSaturated} maxHeight={!isResponsive && height}>
      {lazyLoad && !inViewport ? (
        <PhotoPlaceholder>
          <IconPicture size={48} />
        </PhotoPlaceholder>
      ) : (
        <Image
          alt={name}
          src={photo}
          width={width}
          height={height}
          layout={isResponsive ? 'responsive' : null}
          aspectRatio={aspectRatio}
          {...rest}
        />
      )}
    </PhotoBase>
  );
};

Photo.propTypes = {
  deSaturated: PropTypes.bool,
  height: PropTypes.number,
  isResponsive: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  aspectRatio: PropTypes.string,
};

export default Photo;
